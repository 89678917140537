* {
    box-sizing: border-box;
}

body {
    margin: 0;
    background-color: #272635;
}

a {
    color: white;
    text-decoration: none;
}

.active {
    color: #B1E5F2;
}
